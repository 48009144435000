const Footer = () => {
  return (
    <div className="Footer">
      <p>Designed & Built by Siu Kwan James Yeung.</p>
      <p> &copy; 2022 Siu Kwan James Yeung. All rights reserved.</p>
    </div>
  );
};

export default Footer;
